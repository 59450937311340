import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";
import auth from './modules/auth';
import project from './modules/project';
import user from './modules/user';
import query from './modules/query';


export default createStore({
  modules: {
    auth,
    project,
    user,
    query
  },
  plugins: [createPersistedState()]
});