<template>
  <div class="flex flex-col h-full">
    <div class="flex h-full">
      <div class="h-full w-full lg:block bg-white">
        <router-view class="h-full" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import SideBar from '@/components/SideBar.vue'

//css
import './assets/css/core.css'
import './assets/css/theme-default.css'
import './assets/css/demo.css'
import './assets/css/perfect-scrollbar.css'
import './assets/css/node-waves.css'
import './assets/css/vendor/fonts/fontawesome.css'
import './assets/css/vendor/fonts/tabler-icons.css'

import './assets/js/bootstrap.js'
import './assets/js/menu.js'

export default {
  components: {
    //AppFooter,
  }
}
</script>
