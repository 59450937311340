// import * as Vue from 'vue'
import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import config from '@/config'
import './assets/css/app.css'

//axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.api_server_url
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Content-Type'] = 'application/json'
// Vue.config.productionTip = false;

createApp(App).use(store).use(router).mount('#app')
