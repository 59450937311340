
const config = {
    api_server_url: process.env.VUE_APP_API_SERVER_URL,
    redirect_uri: process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_URL,
    client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
    get_server_url: function(path) {
        return `${process.env.VUE_APP_API_SERVER_URL}${path}`
    },
}
  
export default config
