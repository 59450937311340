import api from '../../api'

const state = {
  project_code: null,
  question: null,
  answer: null,
  userProjects: null,
  isLoading: false,
  links: [],
}

const getters = {
  StateProjectCode: (state) => state.project_code,
  StateQuestion: (state) => state.question,
  StateAnswer: (state) => state.answer,
  StateLinks: (state) => state.links,
  StateUserProjects: (state) => state.userProjects ?? [],
  isLoading: (state) => state.isLoading,
}

const actions = {
  async GetUserProjects({ commit }, user_id) {
    commit('setLoading', true)
    try {
      const response = await api.get(`users/${user_id}/projects`, {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setUserProjects', response.data.projects)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async DoQuery({ commit }, Question) {
    const response = await api.post('query/', Question, {
      headers: {
        ...this.state.auth.auth_header,
      },
    })
    await commit('setAnswer', response.data.answer)
    await commit('setLinks', response.data.links)
    return response.data.answer
  },
  async createChat({ commit }, Question) {
    if (Question) {
      commit('setQuestion', Question.question)
    } else {
      commit('setQuestion', null)
    }

    return Question
  },
}

const mutations = {
  setProjectCode(state, project_code) {
    state.project_code = project_code
  },
  setQuestion(state, question) {
    state.question = question
  },
  setAnswer(state, answer) {
    state.answer = answer
  },
  setLinks(state, links) {
    state.links = links
  },
  setUserProjects(state, projects) {
    state.userProjects = projects
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
