import api from '../../api'

const state = {
  auth_user: null,
  token: null,
  auth_header: null,
  refresh_header: null
}

const getters = {
  isAuthenticated: (state) => !!state.auth_user,
  isAdmin: (state) => !!state.auth_user && state.auth_user.role == 'admin',
  isSuperUser: (state) =>
    !!state.auth_user &&
    (state.auth_user.role == 'superuser' || state.auth_user.role == 'admin'),
  StateAuthUser: (state) => state.auth_user,
  AccessToken: (state) => state.token,
  RefreshToken: (state) => state.refresh_token,
  Authorization: (state) => state.auth_header,
  Avatar: (state) => state.auth_user ? state.auth_user.picture : require('@/assets/img/avatar.png')
}

const actions = {
  async GoogleLogIn({ commit }, code) {
    let auth_user = null
    await commit('LogOut', auth_user)

    const response = await api.post('auth/google', {code: code, state: '/'})
    api.defaults.headers.common = {
      Authorization: `Bearer ${response.data.access_token}`,
    }

    await commit('setAuthUser', response.data)
    return response.data
  },
  async LogIn({ commit }, User) {
    let auth_user = null
    await commit('LogOut', auth_user)

    const response = await api.post('auth/login', User)
    api.defaults.headers.common = {
      Authorization: `Bearer ${response.data.access_token}`,
    }

    await commit('setAuthUser', response.data)
  },
  async LogOut({ commit }) {
    let auth_user = null
    await commit('LogOut', auth_user)
  },
}
const mutations = {
  setAuthUser(state, response) {
    state.auth_user = response.user
    state.token = `Bearer ${response.access_token}`
    state.auth_header = { Authorization: state.token }
    if(response.refresh_token)
      state.refresh_token = response.refresh_token
  },
  LogOut(state) {
    const token_pair = '' + state.token;
    // console.log('logout', token_pair)
    state.auth_user = null
    state.auth_user = null
    state.auth_header = null
    state.refresh_token = null
    if(token_pair){
      const pair = token_pair.split(" ")
        api.post('auth/logout', {access_token: pair[pair.length -1]}).catch(()=>{})
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
