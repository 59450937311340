<template>
  <nav class="bg-white px-6 py-2 border-neutral-300">
    <div
      class="flex justify-between items-center w-full text-regular font-semibold"
    >
      <router-link
        v-if="!isHome && screenSize !== 'sm'"
        to="/trudy-home"
        class="font-bold font-manrope"
        >Home</router-link
      >

      <router-link v-else to="/trudy-home" class="font-bold">
        <img :src="fullLogo" alt="Empresa Logo" class="w-[111px] h-[30px]"
      /></router-link>
      <div class="flex items-center">
        <div class="flex gap-3 items-center">
          <!-- <router-link v-if="isLoggedIn" to="/query" class="nav-link"
            >Query</router-link
          >

          <router-link v-if="isSuperUser" to="/projects" class="nav-link"
            >Projects</router-link
          >

          <router-link v-if="isAdmin" to="/users" class="nav-link"
            >Users</router-link
          > -->

          <div class="relative inline-block text-left" v-if="isLoggedIn">
            <a
              @click="dropdownOpen = !dropdownOpen"
              class="inline-flex justify-center w-full rounded-md px-4 py-2 cursor-pointer"
            >
              <AvatarComponent
                :span="
                  this.$store.getters.StateAuthUser.user_name
                    .substring(0, 2)
                    .toUpperCase()
                "
                size="sm"
                color="bg-secondary text-neutral-900"
              />
            </a>

            <div
              v-show="dropdownOpen"
              @click.away="dropdownOpen = false"
              class="origin-top-right min-w-[250px] absolute border border-neutral-200 z-40 right-6 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <p class="text-neutral-700 text-xs font-semibold p-2.5">
                Your account
              </p>
              <div
                class="py-1 px-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div class="d-flex px-2.5">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar avatar-online">
                      <img :src="avatar" alt class="h-auto rounded-circle" />
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <span
                      class="fw-semibold d-block text-neutral-900 text-sm font-medium"
                      >{{ this.$store.getters.StateAuthUser.name }}</span
                    >
                    <small class="text-muted">{{
                      this.$store.getters.StateAuthUser.user_name
                    }}</small>
                  </div>
                </div>
                <a
                  href="#"
                  class="block py-2.5 px-2.5 my-1 rounded-md text-m font-regular text-neutral-300 hover:bg-neutral-100"
                  role="menuitem"
                  >Manage account</a
                >
                <div class="border-b border-neutral-300"></div>
                <a
                  href="#"
                  class="flex gap-2 rounded-md items-center my-1 py-2.5 px-2.5 text-m font-regular text-neutral-900 hover:bg-neutral-100"
                  role="menuitem"
                  @click="logout"
                  ><i class="ti ti-logout ti-sm"></i> <span>Log Out</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import AvatarComponent from './AvatarComponent.vue'
import { screenSizeMixin } from '../utils/screenSizeMixin'

export default {
  name: 'NavBar',
  components: {
    AvatarComponent
  },
  mixins: [screenSizeMixin],
  data() {
    return {
      // avatar: require('@/assets/img/avatar.png'),
      tnicon: require('@/assets/img/Icon.png'),
      fullLogo: require('@/assets/img/Logo Trudy.png'),
      isHome: false,
      dropdownOpen: false
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated
    },
    isNotLoggedIn: function () {
      return !this.$store.getters.isAuthenticated
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    isSuperUser: function () {
      return this.$store.getters.isSuperUser
    },
    avatar: function () {
      return this.$store.getters.Avatar
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // to, from
        if (this.$route.path == '/') {
          this.isHome = true
        } else {
          this.isHome = false
        }
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    }
  }
}
</script>
