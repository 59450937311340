<template>
  <div class="relative flex flex-col items-center h-[680px] justify-between">
    <div class="flex flex-col pt-8">
      <div
        v-if="current === -1"
        class="flex flex-col items-center !max-w-[631px]"
      >
        <img :src="logo" class="w-44 py-12" />
        <div class="text-4xl font-bold text-neutral-900 font-manrope">
          Meet Trudy
        </div>
        <span
          class="text-regular text-center font-normal text-neutral-700 pt-3"
        >
          Your AI-powered system designed to streamline the process of
          retrieving and understanding information from large volumes of
          TrueNorth project documentation.
        </span>

        <span class="font-m font-semibold text-neutral-900 py-8"
          >Let’s go over how Trudy works before getting started.
        </span>

        <ButtonComponent
          type="secondary"
          size="md"
          class="w-[72px]"
          buttonText="Next"
          @click="next"
        />
      </div>

      <Slide
        v-for="(slide, index) in slides"
        :key="index"
        :imageSrc="slide.imageSrc"
        :subtitle="slide.subtitle"
        :title="slide.title"
        :class="{ hidden: current !== index }"
        :imgStyle="slide.imgStyle"
      />

      <div class="flex justify-between">
        <ButtonComponent
          v-if="current > -1"
          type="neutral"
          size="md"
          class="w-[72px] mt-12"
          buttonText="Back"
          @click="prev"
        />

        <ButtonComponent
          v-if="current !== slides.length - 1 && current !== -1"
          type="secondary"
          size="md"
          class="w-[72px] mt-12"
          buttonText="Next"
          @click="next"
        />
        <ButtonComponent
          v-else-if="current !== -1"
          type="secondary"
          size="md"
          class="w-[100px] mt-12"
          buttonText="Got it"
          @click="gotIt"
          ><template #icon><CheckIcon class="w-5 h-5" /></template
        ></ButtonComponent>
      </div>
    </div>
    <div class="transform -translate-x-1/2 flex space-x-2 ml-16">
      <div
        class="w-3 h-3 rounded-full cursor-pointer"
        :class="{
          'bg-neutral-400': current > -1,
          'bg-secondary-500': current === -1
        }"
        @click="jumpToSlide(index)"
      ></div>
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="w-3 h-3 rounded-full cursor-pointer"
        :class="{
          'bg-neutral-400': current !== index,
          'bg-secondary-500': current === index
        }"
        @click="jumpToSlide(index)"
      ></div>
    </div>
  </div>
</template>

<script>
import Slide from './Slide.vue'
import ButtonComponent from './ButtonComponent.vue'
import api from '../api'
import { CheckIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    Slide,
    ButtonComponent,
    CheckIcon
  },
  data() {
    return {
      current: -1,
      isProcessing: false,
      logo: require('@/assets/img/logoSlide.png'),
      slides: [
        {
          imageSrc: require('@/assets/img/slide1.png'),
          title: 'First, select a project',
          imgStyle: 'left-7',
          subtitle:
            'In order to ask a question or view past chats, select a project from the drop down menu or sidebar.'
        },
        {
          imageSrc: require('@/assets/img/slide2.png'),
          title: 'Then, ask a question',
          imgStyle: 'left-8',
          subtitle:
            'Start chatting with Trudy by typing your question or selecting one of the suggested questions.'
        },
        {
          imageSrc: require('@/assets/img/slide3.png'),
          title: 'Lastly, provide us with feedback',
          imgStyle: 'left-20',
          subtitle:
            'Help us to improve Trudy by rating each answer with a thumbs up or thumbs down.'
        }
      ]
    }
  },
  methods: {
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    gotIt() {
      // Llamar al endpoint aquí
      this.callEndpoint()
    },
    jumpToSlide(index) {
      this.current = index
    },
    async callEndpoint() {
      const userId = this.$store.getters.StateAuthUser.id
      let vm = this
      vm.isProcessing = true
      try {
        await api.patch(
          `users/${userId}`,
          { welcome_got_it: true },
          {
            headers: {
              ...this.$store.getters.Authorization
            }
          }
        )
        console.log('GOT IT SUCCESS!!')
        this.$router.push('/trudy-home')
      } catch (err) {
        console.log('FAILURE!!', err)
      } finally {
        vm.isProcessing = false
      }
    }
  }
}
</script>
