<template>
  <button
    :class="[
      'flex',
      'rounded',
      'disabled:opacity-50',
      'disabled:cursor-not-allowed',
      'outline-none',
      'font-semibold',
      'focus-visible:ring-2',
      'ring-offset-1',
      'ring-focus',
      'group',
      'border-transparent',
      'hover:enabled:duration-200',
      'items-center',
      'justify-center',
      'gap-1',
      'hover:enabled:ease-out' + sizeClass + ' ' + typeClass + ' ' + buttonStyle
    ]"
    :disabled="isDisable"
    type="submit"
  >
    <div v-if="isLoading" role="status">
      <pulse-loader
        :loading="isLoading"
        :color="color"
        :size="sizeL"
      ></pulse-loader>
    </div>
    <span v-else-if="buttonText">{{ buttonText }}</span>
    <slot name="icon"></slot>
  </button>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  components: {
    PulseLoader
  },
  name: 'ButtonComponent',
  data() {
    return {
      color: '#2E3488',
      sizeL: '5px'
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonStyle: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md', 'lg'].includes(value)
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'neutral'].includes(value)
    }
  },
  computed: {
    typeClass() {
      const typeToClassMapping = {
        primary: [
          'text-white bg-primary-500 hover:enabled:bg-primary-400 active:enabled:bg-primary-500'
        ],
        neutral: [
          'text-neutral-700 bg-white hover:enabled:bg-neutral-400 !border !border-neutral-400 active:enabled:bg-primary-500'
        ],
        secondary:
          'text-neutral-900 bg-secondary-500 hover:enabled:bg-secondary-400 active:enabled:bg-secondary-600'
      }
      return typeToClassMapping[this.type] || ''
    },
    sizeClass() {
      const sizeToClassMapping = {
        sm: 'py-1.5  px-4  text-sm h-9',
        md: 'py-2 px-3 text-sm h-10',
        lg: 'py-2 px-5 text-m h-11'
      }
      return sizeToClassMapping[this.size] || ''
    }
  }
}
</script>
