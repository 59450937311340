<template>
  <div class="flex flex-col h-full">
    <Carousel />
  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue'

export default {
  name: 'HomeView',
  components: {
    Carousel
  },
  data() {
    return {
      logo: require('@/assets/img/home.png')
    }
  }
}
</script>
