import api from '../../api'

const state = {
  projects: null,
  project: null,
  projectUsers: null,
  isPublicProject: false,
  isLoading: false,
}

const getters = {
  StateProjects: (state) => state.projects,
  StateProject: (state) => state.project,
  StateProjectUsers: (state) => state.projectUsers,
  isPublicProject: (state) => state.project && state.project.public,
  isLoading: (state) => state.isLoading,
}

const actions = {
  async GetProjects({ commit }) {
    commit('setLoading', true)
    try {
      const response = await api.get('projects/', {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setProjectss', response.data.projects)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async GetProject({ commit }, project_code) {
    commit('setLoading', true)
    try {
      let response = await api.get(`projects/${project_code}`, {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setProject', response.data.project)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async GetProjectsByUser({ commit }, project_code) {
    commit('setLoading', true)
    commit('setProjectUsers', null)
    try {
      let response = await api.get(`projects/${project_code}/users`, {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setProject', response.data.project)
      commit('setProjectUsers', response.data.users)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  },
  setProject(state, project) {
    state.project = project
  },
  setProjectUsers(state, projectUsers) {
    state.projectUsers = projectUsers
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
