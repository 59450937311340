<template>
  <div>
    <header>
      <NavBar class="z-10" />
    </header>
    <main>
      <router-view class="p-4" />
    </main>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'NavBarLayout',
  components: { NavBar }
}
</script>
