<template>
  <div class="relative items-center !max-w-[409px]">
    <div class="relative w-full h-64 bg-home-gradient rounded">
      <img :src="imageSrc" :class="['absolute h-auto top-6', imgStyle]" />
    </div>

    <div
      class="text-center font-manrope font-semibold text-2xl text-neutral-900 mt-20"
    >
      {{ title }}
    </div>
    <div class="text-center text-m font-regular mt-4 text-neutral-700">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    imgStyle: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
/* Estilos adicionales si son necesarios */
</style>
