<template>
  <div :class="['relative rounded-full	', sizeClass]">
    <img
      v-if="img"
      :src="img"
      alt="Avatar"
      :class="['rounded-full p-1', color]"
    />

    <span
      v-else-if="span"
      :class="[
        'absolute inset-0 uppercase flex items-center justify-center font-semibold rounded-full',
        color,
      ]"
      >{{ span }}</span
    >

    <i v-else-if="icon" class="icon">{{ icon }}</i>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    span: String,
    icon: String,
    color: String,
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md', 'lg'].includes(value),
    },
  },
  computed: {
    sizeClass() {
      const sizeToClassMapping = {
        sm: 'w-8 h-8',
        md: 'w-10 h-10',
        lg: 'w-12 h-12',
      }
      return sizeToClassMapping[this.size] || ''
    },
  },
}
</script>
