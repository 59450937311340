export const screenSizeMixin = {
  data() {
    return {
      screenSize: this.calculateScreenSize()
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize)
  },
  methods: {
    updateScreenSize() {
      this.screenSize = this.calculateScreenSize()
    },
    calculateScreenSize() {
      const width = window.innerWidth

      if (width < 640) return 'sm'
      if (width < 768) return 'md'
      if (width < 1024) return 'lg'
      if (width < 1280) return 'xl'
      if (width < 1536) return '2xl'
      return '3xl'
    }
  }
}
