import api from '../../api'

const state = {
  users: null,
  user: null,
  status: false,
  isLoading: false,
}

const getters = {
  StateUsers: (state) => state.users,
  StateUser: (state) => state.user,
  isLoading: (state) => state.isLoading,
}

const actions = {
  async GetUsers({ commit }) {
    commit('setLoading', true)
    try {
      let response = await api.get('users/', {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setUsers', response.data.users)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async GetUser({ commit }, user_id) {
    commit('setLoading', true)
    try {
      const response = await api.get(`users/${user_id}`, {
        headers: {
          ...this.state.auth.auth_header,
        },
      })
      commit('setUser', response.data.user)
    } catch (error) {
      console.error(error)
    } finally {
      commit('setLoading', false)
    }
  },
  async Register({ commit }, user) {
    let response = await api.post('users/', user)
    console.log(response)
    await commit('setStatus', 'user created')
  },
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setUser(state, user) {
    state.user = user
  },
  setStatus(state, status) {
    state.status = status
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
