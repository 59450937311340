import { createRouter, createWebHistory } from 'vue-router'
// , createWebHashHistory
import HomeView from '../views/HomeView.vue'
import store from '../store'
import NavBarLayout from '../layouts/NavBarLayout.vue'

const routes = [
  {
    path: '/old-login',
    name: 'LoginView',
    meta: { guest: true },
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/project-home/:code',
    name: 'ProjectHome',
    meta: { requiresAuth: true },
    component: () => import('../views/ChatView/ProjectHome.vue'),
  },
  {
    path: '/trudy-home',
    name: 'TrudyHome',
    meta: { requiresAuth: true },
    component: () => import('../views/ChatView/TrudyHome.vue'),
  },
  {
    path: '/verify',
    name: 'VerifyView',
    meta: { guest: true },
    component: () => import('../views/VerifyView.vue'),
  },
  {
    path: '/register',
    name: 'RegisterView',
    meta: { guest: true },
    component: () => import('../views/RegisterView.vue'),
  },
  {
    path: '/login',
    name: 'LoginGoogleView',
    meta: { guest: true },
    component: () => import('../views/LoginGoogleView.vue'),
  },
  {
    path: '/',
    component: NavBarLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'home',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: HomeView,
      },
      {
        path: '/query',
        name: 'QueryView',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: () => import('../views/QueryView.vue'),
      },

      {
        path: '/projects/:code',
        name: 'ProjectView',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: () => import('../views/ProjectView.vue'),
      },
      {
        path: '/projects/:code/users',
        name: 'ProjectUsersView',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: () => import('../views/ProjectUsersView.vue'),
      },

      {
        path: '/projects',
        name: 'ProjectList',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: () => import('../views/ProjectsView.vue'),
      },

      {
        path: '/users',
        name: 'UserList',
        meta: { requiresAuth: true, layouts: NavBarLayout },
        component: () => import('../views/UsersView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login')
      return
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next('/home')
      return
    }
  }
  next()
})

export default router
