import axios from 'axios'
// import router from './router'
import store from './store'
import config from '@/config'

const api = axios.create({
  baseURL: config.api_server_url,
})
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log('error.config', error.config)
    const originalConfig = error.config

    if (originalConfig.url !== 'auth/login' && error.response) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const refreshToken = store.getters.RefreshToken

          api.defaults.headers.common = undefined
          const rs = await api.post('/auth/token', {
            refresh_token: refreshToken,
          })
          api.defaults.headers.common = {
            Authorization: `Bearer ${rs.data.access_token}`,
          }
          store.commit('setAuthUser', rs.data)
          return api(originalConfig)
        } catch (_error) {
          console.log(error.response.status)
          store
            .dispatch('LogOut')
            .then(function (){})
            .catch(function (err){
              console.log(err)}
            );
          window.location = '/login'
        }
      }
    }

    // if (error.response && error.response.status === 401) {
    //   console.log(error.response.status)
    //   store.dispatch('LogOut').then(function (){}).catch(function (err){console.log(err)});
    //   window.location = '/#/login'
    // }
    return Promise.reject(error)
  }
)

export default api
